const $ = jQuery;

export default class Modal {
    constructor(options = null) {}

    init() {
        this.__modal1();
    }

    __modal1() {
        let teamModal = document.getElementById("teamModal");

        teamModal.addEventListener("show.bs.modal", (event) => {
            // Button that triggered the modal
            let button = event.relatedTarget;
            // Extract info from data-bs-* attributes
            let name = button.getAttribute("data-bs-name");
            let title = button.getAttribute("data-bs-title");
            let bio = button.getAttribute("data-bs-bio");
            let base64Html = button.getAttribute("data-bs-image");

            // Update the modal's content.
            let modalTitle = teamModal.querySelector(".modal__title");
            let modalName = teamModal.querySelector(".modal__name");
            let modalBio = teamModal.querySelector(".modal__bio");
            let modalImg = teamModal.querySelector(".modal__image");

            // Decode the HTML
            var decodedHtml = this.__decodeBase64(base64Html);

            modalName.innerHTML = name;
            modalTitle.innerHTML =
                '<p class="text-uppercase introduction">' + title + "</p>";
            modalBio.innerHTML = bio;
            modalImg.innerHTML = decodedHtml;
        });
    }

    __decodeBase64(encodedStr) {
        // Decode base64 and create a new DOMParser to parse the HTML
        let decodedStr = atob(encodedStr);
        let parser = new DOMParser();
        return parser.parseFromString(decodedStr, "text/html").body.innerHTML;
    }
}
