const $ = jQuery;

export default class StickyNav {
    constructor(options = null) {
        this.$navbar = $("header");
        this.$menuItem = $("#menu-primary>.menu-item");
        this.$menu = $(".menu");
        this.$body = $("body");
        this.$lastWindowScrollTop = 0;
    }

    init() {
        this.__listen();
    }

    __listen() {
        this.__burger();
        this.__stickFunction();
        this.__submenu();
    }

    __burger() {
        this.$body.on("click", "#burger", (event) => {
            event.preventDefault();
            const target = event.target;
            $(target).toggleClass("open");

            const navbar = this.$navbar[0];

            if (this.$navbar.hasClass("header__stickyNav--burger")) {
                navbar.classList.remove("header__stickyNav--burger");
                this.$body.removeClass("non-ios-noscroll");
                window.scrollTo({
                    top: this.$lastWindowScrollTop,
                    behavior: "instant",
                });
            } else {
                this.$lastWindowScrollTop = $(window).scrollTop();
                navbar.classList.add("header__stickyNav--burger");
            }

            this.$menu.toggleClass("open");
            // this.$body.toggleClass('fixed');

            if (this.$menu.hasClass("open")) {
                $(".menu-primary-container").trigger("show");
                setTimeout(() => {
                    document
                        .querySelectorAll("#menu-primary>li")
                        .forEach((li, i) => {
                            setTimeout(() => {
                                li.classList.add("show");
                            }, 100 * i);
                        });
                }, 500);
                setTimeout(() => {
                    this.$body.addClass("non-ios-noscroll");
                }, 500);
            } else {
                $(".menu-primary-container").trigger("hide");
                setTimeout(() => {
                    this.$menuItem.removeClass("show");
                }, 500);
            }

            this.$menuItem.removeClass("open");
        });
    }

    __submenu() {
        this.$body.on(
            "click",
            "#menu-primary>.menu-item.menu-item-has-children>a",
            (event) => {
                event.preventDefault();

                const target = event.currentTarget;
                const parentElement = $(target).parent();

                if ($(parentElement).hasClass("open")) {
                    $(parentElement).removeClass("open");
                    target.nextElementSibling.classList.remove("open");
                } else {
                    this.$menuItem.removeClass("open");
                    $(parentElement).addClass("open");
                    target.nextElementSibling.classList.add("open");
                }
            }
        );
    }

    __stickFunction() {
        const navbar = this.$navbar[0];
        const sticky = navbar.offsetTop + 1;

        window.addEventListener("load", () => {
            if (window.pageYOffset > 1) {
                navbar.classList.add("header__stickyNav");
            }
        });

        window.onscroll = function () {
            if (window.pageYOffset > sticky) {
                navbar.classList.add("header__stickyNav");
            } else {
                navbar.classList.remove("header__stickyNav");
            }
        };
    }
}
