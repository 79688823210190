import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const $ = jQuery;

export default class Swipers {
    constructor(options = null) {}

    init() {
        this.__swiper1();
    }

    init_2() {
        this.__swiper2();
    }

    init_general() {
        this.__swiper_general();
    }

    __swiper1() {
        const $swiperBlocks = $(".swiper--testimonials");
        const $swiperBlocks2 = $(".swiper--author");

        const testimonialsSwipers = [];
        const authorSwipers = [];

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiperOptions = {
                speed: 1000,
                slidesPerView: 1,
                spaceBetween: 22.5,
                autoplay: {
                    enabled: true,
                    delay: 6000,
                },
                loop: true,
                pagination: {
                    el: ".swiper-pagination-" + swiperBlockId,
                    type: "bullets",
                    clickable: false,
                },
            };

            const swiperInstance = new Swiper(
                "#" + swiperBlockId,
                swiperOptions
            );
            testimonialsSwipers.push(swiperInstance);
        }

        for (const swiperBlock of $swiperBlocks2) {
            const swiperBlockId = $(swiperBlock).attr("id");

            const swiperOptions = {
                speed: 1000,
                slidesPerView: 1,
                spaceBetween: 22.5,
                autoplay: false,
                loop: true,
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
            };

            const swiperInstance = new Swiper(
                "#" + swiperBlockId,
                swiperOptions
            );
            authorSwipers.push(swiperInstance);
        }

        for (const testimonialsSwiper of testimonialsSwipers) {
            for (const authorSwiper of authorSwipers) {
                testimonialsSwiper.controller.control = authorSwiper;
            }
        }
    }

    __swiper2() {
        const $swiperBlocks = $(".swiper--category");

        $swiperBlocks.each(function () {
            const swiperBlockId = $(this).attr("id");

            var catswiper = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                slidesPerView: 1,
                allowTouchMove: false,
                autoplay: false,
                loop: true,
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
            });

            //control sliders
            const $swiperNavs = $(
                '.swiper-nav[data-slider-id="' + swiperBlockId + '"]'
            );
            $swiperNavs.each(function () {
                const slideNumber = $(this).attr("data-slide");

                $(this).on("click", () => {
                    $swiperNavs.removeClass("active");
                    $(this).addClass("active");
                    console.log("slideNumber", slideNumber);
                    catswiper.slideTo(slideNumber);
                });
            });
        });
    }

    __swiper_general() {
        const $swiperBlocks = $(".swiper--general");
        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");
            const slidesPerViewAttr =
                swiperBlock.getAttribute("data-slides-per-view") || 1;
            const loop = swiperBlock.getAttribute("data-loop") === "true";
            const navigation = swiperBlock.getAttribute("data-navigation");
            const pagination = swiperBlock.getAttribute("data-pagination");
            const speed = swiperBlock.getAttribute("data-speed") || 1000;
            const spaceBetweenAttr =
                swiperBlock.getAttribute("data-space-between") || 10;
            const autoPlay =
                swiperBlock.getAttribute("data-autoplay") === "true";

            let slidesPerView = parseAttribute(slidesPerViewAttr);
            let spaceBetween = parseAttribute(spaceBetweenAttr);

            let nav = null;
            let pag = null;

            if (navigation == "true") {
                nav = {
                    nextEl: ".swiper-next-" + swiperBlockId,
                    prevEl: ".swiper-prev-" + swiperBlockId,
                };
            } else {
                nav = {
                    nextEl: null,
                    prevEl: null,
                };
            }

            if (pagination == "true") {
                pag = {
                    el: ".swiper-pagination-" + swiperBlockId,
                    type: "bullets",
                    clickable: false,
                };
            } else {
                pag = {
                    el: null,
                    type: null,
                };
            }

            let breakpointsCount = Array.isArray(slidesPerView)
                ? slidesPerView.length
                : 1;

            // Ensure slidesPerView and spaceBetween are arrays
            if (!Array.isArray(slidesPerView)) slidesPerView = [slidesPerView];
            if (!Array.isArray(spaceBetween)) spaceBetween = [spaceBetween];

            const responsiveSettings = {};
            const widths = [991, 768, 576, 0];

            for (let i = 0; i < breakpointsCount; i++) {
                responsiveSettings[widths[i]] = {
                    slidesPerView: slidesPerView[i] || slidesPerView[0],
                    spaceBetween: spaceBetween[i] || spaceBetween[0],
                };
            }

            const swiper = new Swiper("#" + swiperBlockId, {
                speed: parseInt(speed, 10),
                slidesPerView: slidesPerView[0],
                spaceBetween: spaceBetween[0],
                autoplay: autoPlay,
                loop: loop,
                navigation: nav,
                pagination: pag,
                breakpoints: responsiveSettings,
            });
        }
        function parseAttribute(attr) {
            try {
                return JSON.parse(attr);
            } catch (e) {
                return [parseInt(attr)];
            }
        }
    }
}
