// import js dependencies
import StickyNav from "./sticky-nav";
import AjaxPost from "./ajax";
import Swipers from "./swiper";
import Modal from "./modal";

jQuery(document).ready(($) => {
    //disable scroll script
    // var lastWindowScrollTop = 0,
    //     userAgent = navigator.userAgent,
    //     $body = $("body"),
    //     isIOS = /iPhone|iPad/.test(userAgent),
    //     NO_SCROLL_CLASS = isIOS ? "ios-noscroll" : "non-ios-noscroll";

    function fixedBody() {
        if (isIOS) {
            // lastWindowScrollTop = $(window).scrollTop();
            // $body.addClass(NO_SCROLL_CLASS);
            // $body.css("top", "-" + lastWindowScrollTop + "px");
        } else {
            // lastWindowScrollTop = $(window).scrollTop();
            // console.log(lastWindowScrollTop);
            // $body.addClass(NO_SCROLL_CLASS);
        }
    }

    // function looseBody() {
    //     $body.removeClass(NO_SCROLL_CLASS);
    //     if (isIOS) {
    //         $body.css("top", "");
    //         window.scrollTo(0, lastWindowScrollTop);
    //     } else {
    //         $body.css("top", "");
    //         window.scrollTo(0, lastWindowScrollTop);
    //     }
    // }

    // $.fn.scrollableOverlay = function () {
    //     this.on("show", fixedBody);
    //     this.on("hide", looseBody);
    // };

    // $(".menu-primary-container").scrollableOverlay();

    const stickyNav = new StickyNav({});
    stickyNav.init();

    // SWIPERS
    const swipers = new Swipers({});

    if ($(".swiper--general").length > 0) {
        swipers.init_general();
    }

    if ($(".swiper--testimonials").length > 0) {
        swipers.init();
    }

    if ($(".swiper--category").length > 0) {
        swipers.init_2();
    }

    if ($(".meet_the_team").length > 0) {
        const modal = new Modal({});
        modal.init();
    }

    const ajaxPost = new AjaxPost({});
    ajaxPost.init();
});
